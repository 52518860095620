import {FormField} from "../input";
import {SettingsGroup} from "../settingsGroup";
import React from "react";
import {SingleSelect} from "../singleSelect";

export const TransactionSettings = ({state, setState, qboAccNames}) => {
    if (state.integration_name === 'FLOWHUB' || state.integration_name === 'CANIX') return

    return (
        <SettingsGroup groupName={<div id='TRANSACTION'>Vault Transfers</div>}
                       reportType='TRANSACTION'
                       name='report_types'
                       state={state}
                       setState={setState}
                       tooltipContent='Turn on to enable QBO Transfers. Module creates separate transfers for each Closeout and Cash Drop transaction from the register to the cash vault. Granularity: by register, day'>
            <SingleSelect groupName='Vault Transactions'
                          formName='Vault account name'
                          state={state}
                          setState={setState}
                          name='vault_acc_name'
                          tooltipContent='Account destination for such transfers.'
                          options={qboAccNames}
            />
        </SettingsGroup>
    )
}