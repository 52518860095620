import {getUrl} from "../docs/data";
import {setUserSession, getAccountSettings, getHeader} from "./sessionStorage";
import axios from "axios";


const checkProgress = (qboAccName, resultName, messageHandler) => {
    console.log('Checking run results for:', qboAccName, resultName);
    let runLock = getAccountSettings(qboAccName, 'run_lock');
    runLock = runLock ? JSON.parse(runLock) : runLock;
    let runResult = getAccountSettings(qboAccName, resultName);
    runResult = runResult ? JSON.parse(runResult) : runResult;

    if (runLock['timeout/memory']) {
        console.log('Finished with timeout')
        const dates = Object.keys(runResult);
        const max_date = dates.reduce((a, b) => a > b ? a : b);

        messageHandler.setMessage('Finished early. The last date uploaded: ' + max_date);
        messageHandler.setVisible(true);
        messageHandler.setColor('warning');
        return 100
    } else if (runLock['finished']) {
        console.log('Finished the run')
        const dates = Object.keys(runResult);
        const max_date = dates.reduce((a, b) => a > b ? a : b);

        messageHandler.setMessage('Finished successfully. The last date uploaded: ' + max_date);
        messageHandler.setVisible(true);
        messageHandler.setColor('primary');
        return 100
    } else if (runResult) {
        let startDate = new Date(runLock['start_date'])
        let endDate = runLock['end_date'] ? new Date(runLock['end_date']) : new Date()
        let delta = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24), 10)
        let processed = Object.keys(runResult).length

        console.log('Current progress: ', (processed / delta * 100))
        return Math.floor((processed / delta * 100), 0)
    } else {
        console.log('No progress yet')
        return 0
    }
}

const pollRunResults = async (qboAccount, resultName, setProgress, messageHandler) => {
    console.log('Polling server for run results.')
    const url = getUrl('verify')
    let header = getHeader()

    await new Promise(resolve => setTimeout(resolve, 1000 * 60));
    try {
        const response = await axios.get(url, header);
        if (response.status === 200) {
            await setUserSession(response.data, response.headers);
            let progress = await checkProgress(qboAccount, resultName, messageHandler);
            setProgress(progress)
            if (progress === 100) {
                return progress;
            } else {
                console.log('Polling further')
                await pollRunResults(qboAccount, resultName, setProgress, messageHandler);
            }
        } else {
            console.log('Finished polling')
        }
    } catch (e) {
        console.log(e)
    }
}

export {pollRunResults, checkProgress}