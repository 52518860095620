export const settingsValidation = (qboAccount, state) => {
    let result
    switch(true) {
        case !qboAccount:
            result = 'QBO Account required.'
            break
        case (state.integration_name !== 'FLOWHUB' && !state.pos_timezone):
            result = 'Time Zone field is required.'
            break
        case (!state.integration_name):
            result = 'Integration name is required.'
            break
        case (state.dutchie_key && state.dutchie_key.length !== 32):
            result = 'Check Dutchie key: should be 32 characters long'
            break
        case (state.canix_token && state.canix_token.length !== 64):
            result = 'Check Canix token: should be 64 characters long'
            break
        case ((state.flow_token && state.flow_token.length !== 36) || (state.flow_client_id && state.flow_client_id.length !== 36)):
            result = 'Check Flow token and ClientId: should be 34 characters long'
            break
        default:
            result = false
    }
    return result
}