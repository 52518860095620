import React from 'react';
import Select from 'react-select';
import {FormGroup, Label, UncontrolledTooltip} from 'reactstrap';


function MultiSelect(props) {
    const handleInput = (event) => {
        let choices = JSON.stringify(event .map(x => x.value))
        props.state[props.name] = choices
        props.setState({...props.state})
    }



    return (
        <FormGroup>
            <Label for={props.groupName} id={props.name} targetId={props.name}>
                {props.formName}
            </Label>
            <Select
                defaultValue={props.options}
                isMulti
                name="colors"
                options={props.options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleInput}
            />
            <UncontrolledTooltip placement="top" target={props.name}>
                {props.tooltipContent}
            </UncontrolledTooltip>
        </FormGroup>
    );
}

export default MultiSelect;