import React, {useEffect, useState} from 'react';
import {Alert, Container} from 'reactstrap';
import Cookies from "js-cookie";

export const EmailStatus = () => {
    const [type, setType] = useState('warning')
    const [message, setMessage] = useState('Request is not valid. Please use another link or contact system administrator.')

    useEffect(() => {
        let emailValidationStatus = Cookies.get('email-validation');
        Cookies.remove('email-validation')
        console.log('Searching email-validation', emailValidationStatus)
        if (emailValidationStatus === 'success') {
            setMessage('Email validated successfully. Please login to start the session.')
            setType('primary')
        }
    }, [])

    return (
        <Container className="border-light" fluid>
            <div className="holderDiv">
                <br></br>
                <br></br>
                <Alert color={type}>
                    {message}
                </Alert>
            </div>
        </Container>
    )
}
