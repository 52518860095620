import React, {useEffect, useState} from 'react';
import {BrowserRouter, NavLink as RRNavLink, Route, Switch} from "react-router-dom";
import {
    Navbar, NavbarBrand,
    Nav, NavItem, NavLink, NavbarText
} from 'reactstrap';
import PrivateRoute from './routes/privateRoute';

import Register from "./Register";
import Login from "./Login";
import Settings from "./Settings";
import {logOutHandler} from "./service/sessionStorage";
import {EmailStatus} from "./EmailStatus";

function App() {
    const [collapsed, setCollapsed] = useState(true);
    const [login, setLogin] = useState(false);
    useEffect(() => {
        sessionStorage.getItem('token') ? setLogin(true) : setLogin(false)
    }, [window.location.pathname]);

    const toggleNavbar = () => setCollapsed(!collapsed);

    return (
    <div className="App">
      <BrowserRouter>
        <Navbar className="navBar" dark expand="ml-auto">
            <NavbarBrand href="/">
                <img className='logoBig' alt="logo" src="/logo.png" style={{height: 50}}/>
                <img className='logoSmall' alt="logo" src="/logo-small.png" style={{height: 50}}/>
            </NavbarBrand>
            <NavbarText>
                <NavLink tag={RRNavLink}
                                 activeCalssName="active"
                                 to="/login-pg"
                                 onClick={() => logOutHandler(login, setLogin)}>
                {login ? 'Log out': 'Log in'}
            </NavLink>
            </NavbarText>
        </Navbar>
        <div className="content">
          <Switch>
            <Route exact path="/" component={() => {
                window.location.href = 'https://palomino.consulting/solution/racetrack/';
                return null;}
            }/>
            <Route path="/register-pg" component={Register}/>
            <Route path="/login-pg" component={Login}/>
            <Route path="/email-status" component={EmailStatus}/>
            <Route path="/reset-password" component={EmailStatus}/>
            <Route path="/update-password" component={EmailStatus}/>
            <Route exact path="/terms-page" component={() => {
                  window.location.href = 'https://palomino.consulting/terms-and-conditions/';
                  return null;}
            }/>
            <Route exact path="/privacy-page" component={() => {
                  window.location.assign('https://palomino.consulting/privacy-policy-2/');
                  return null;}
            }/>
            <Route exact path="/dutchie" component={() => {
                  window.location.href = 'https://palomino.consulting/dutchie';
                  return null;}
            }/>
            <Route exact path="/flowhub" component={() => {
                  window.location.href = 'https://palomino.consulting/flowhub';
                  return null;}
            }/>
            <Route exact path="/contact-page" component={() => {
                  window.location.href = 'https://palomino.consulting/contact';
                  return null;}
            }/>
            <PrivateRoute path="/settings-page" setLogin={setLogin} component={{advanced: Settings}}/>
          </Switch>
        </div>
          <Nav className="navFooter">
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/dutchie">Racetrack + Dutchie</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/flowhub">Racetrack + FlowHub</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/terms-page">Terms & Conditions</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/privacy-page">Privacy Policy</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink" tag={RRNavLink} activeCalssName="active" to="/register-pg">Register</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink" tag={RRNavLink} activeCalssName="active" to={login ? "/settings-page": "/login-pg"} >{login ? 'Settings': 'Log in'}</NavLink>
              </NavItem>
              <NavItem>
                  <NavLink className="navLink"  tag={RRNavLink} activeCalssName="active" to="/contact-page">Contact</NavLink>
              </NavItem>
          </Nav>
      </BrowserRouter>
    </div>
  );
}

export default App;
