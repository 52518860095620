import {FormField} from "../input";
import {SettingsGroup} from "../settingsGroup";
import React from "react";
import {Toggle} from "../toggle";

export const FlowhubIncomeSettings = ({state, setState}) => {
    return (
        <SettingsGroup groupName={<div id='INCOME'>Sales & Refund Receipts</div>}
                       reportType='INCOME'
                       name='report_types'
                       state={state}
                       setState={setState}
                       tooltipContent='Turn on to enable module. Creates receipts that holds sales summary for the day under register-named account. Summary: sales, discounts, rounding, non-revenue donations, cogs, tax (by type). Granularity: by register, day.'>
            <Toggle toggleName='Merge discounts into sales'
                    state={state}
                    setState={setState}
                    name='income_merge_discounts'
                    tooltipContent='Disables creation of dedicated Discounts item in sales receipt. Subtracts discounts sum from the Sales item. '/>
            <Toggle toggleName='Sales breakdown by product category'
                    state={state}
                    setState={setState}
                    name='income_breakdown'/>
            <Toggle toggleName='COGS breakdown by product category'
                    state={state}
                    setState={setState}
                    name='income_cogs_breakdown'/>
            <Toggle toggleName='Discounts breakdown by product category'
                    state={state}
                    setState={setState}
                    name='income_discounts_breakdown'/>
            <FormField groupName='Sales Tracking Class'
                       formName='Tracking class for sales recipes'
                       placeholder='Default value: not classed'
                       name='income_tracking_class_map'
                       state={state}
                       setState={setState}
                       tooltipContent='Fill in transaction type name and class it should relate to according to the template:
                               {"transactionType":"ClassName"}. Put ALL_TX_TYPES, if you want to assign single class name to all receipts.'/>
            <FormField groupName='Custom Tax Namings Map'
                       formName='Tax names mapping'
                       placeholder='Possible value: {"Sales Tax": "Sales Tax Payable"}'
                       name='income_tax_naming_map'
                       state={state}
                       setState={setState}
                       tooltipContent='Relation map. Can be used to rename taxes in QBO or assign several taxes from POS to a single QBO item in sales receipt.
                               Template: {"Sales Tax":"Sales Tax Payable","Excise Tax":”Excise Tax Liability"}'/>
            <FormField groupName='Sales & Returns'
                       formName='Customer name placeholder'
                       placeholder='Default value: *POS Sales Info'
                       name='sales_customer_name'
                       state={state}
                       setState={setState}/>
            <FormField groupName='Custom sales item naming'
                       formName='Custom sales item naming'
                       placeholder='Default value: Sales'
                       name='income_sales_line_name'
                       state={state}
                       setState={setState}/>
        </SettingsGroup>
    )
}