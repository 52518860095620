import {Input, Label, FormGroup, UncontrolledTooltip} from "reactstrap";
import React, {useEffect, useState} from "react";

function Toggle(props) {
    const [value, setValue] = useState('')
    useEffect(() => {setValue(props.state[props.name])}, [props.state])

    const handleInput = (event) => {
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        props.state[name] = value
        props.setState({...props.state})
        setValue(value)
    }


    return (
        <FormGroup>
            <Label id={props.name}>
                {props.toggleName}
            </Label>
            <br></br>
            <Input
                type="checkbox"
                name={props.name}
                defaultChecked={props.state[props.name]}
                onClick={handleInput}
                checked={value}
            />
            {props.tooltipContent &&
                <UncontrolledTooltip placement="top" target={props.name}>
                    {props.tooltipContent}
                </UncontrolledTooltip>}
        </FormGroup>
    );
}

export {Toggle};