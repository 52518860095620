import React, {useEffect, useState} from 'react';
import {
    FormGroup,
    Label,
    Input,
    UncontrolledTooltip
} from 'reactstrap';


function FormField(props) {
    const [value, setValue] = useState('')
    useEffect(() => {
        let val = props.state[props.name]
        if (val !== null && typeof(val) === 'object') val = JSON.stringify(val)
        setValue(val)
    }, [props.state])

    const handleInput = (event) => {
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        props.state[name] = value
        props.setState({...props.state})
        setValue(value)
    }

    return (
        <FormGroup>
            <Label for={props.groupName} id={props.name}>
                {props.formName}
            </Label>
            <Input type={props.type !== "text" ? props.type : "text" }
                   placeholder={props.placeholder}
                   name={props.name}
                   value={value}
                   onChange={handleInput}/>
            {props.tooltipContent &&
                <UncontrolledTooltip placement="top" target={props.name}>
                    {props.tooltipContent}
                </UncontrolledTooltip>}
        </FormGroup>
    );
}

export {FormField};
