import {FormField} from "../input";
import {UncontrolledSettingGroup} from "../settingsGroup";
import React from "react";
import {BasicSelector} from "../select";
import {accountingBasis} from "../../docs/data";
import {SingleSelect} from "../singleSelect";

export const FlowhubGlobalSettings = ({state, setState, qboAccNames}) => {
    return (
        <UncontrolledSettingGroup groupName='Global Settings'>
            <SingleSelect groupName='Global Settings'
                          formName='Registers Parent Bank Account Name'
                          name='parent_acc_name'
                          state={state}
                          setState={setState}
                          tooltipContent='This account will be parent to all register-dedicated accounts. In case of Lite mode - this will be the account name to hold all sales receipts and debit journals'
                          options={qboAccNames}/>
            <FormField groupName='Flowhub API Key'
                       formName='Flowhub API Key'
                       placeholder='36-figure key'
                       state={state}
                       setState={setState}
                       name='flow_token'
                       type='password'
                       tooltipContent='Example: dgkee4de-5238-4d12-86a9-f29845a3f429'/>
            <FormField groupName='Flowhub Client Id'
                       formName='Flowhub Client Id'
                       placeholder='36-figure Id'
                       state={state}
                       setState={setState}
                       name='flow_client_id'
                       type='password'
                       tooltipContent='Example: dgkee4de-5238-4d12-86a9-f29845a3f429'/>
            <FormField groupName='Flowhub Location Id'
                       formName='Flowhub Location Id'
                       placeholder='36-figure Id'
                       state={state}
                       setState={setState}
                       name='flow_location_id'
                       tooltipContent='Example: dgkee4de-5238-4d12-86a9-f29845a3f429'/>
            <BasicSelector formName='Accounting Basis'
                           name='accounting_basis'
                           componentName='AccountingBasis'
                           state={state}
                           setState={setState}
                           items={accountingBasis}/>
        </UncontrolledSettingGroup>
    )
}