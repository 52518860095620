import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion, UncontrolledTooltip
} from 'reactstrap';
import {defaultDebitTypes, inventoryCategoryStatus} from "../docs/data";
import {getUserSettings} from "../service/sessionStorage";

function SettingsGroup(props) {
    const [open, setOpen] = useState('0');

    useEffect(() => {
        const reports = props.state[props.name] ? props.state[props.name] : []
        if (reports.includes(props.reportType)) {
          setOpen(props.reportType);
        }
        else {
          setOpen("0")
        }
      }, [props.state]);

    const toggle = (id) => {
        let reports = props.state[props.name] ? props.state[props.name] : []
        if (reports.length === 0) {
            props.state[props.name] = []
            props.setState(props.state)
        }
        if (open === id) {
          setOpen("0");
          props.state[props.name] = reports.filter(function(e) { return e !== props.reportType });
          props.setState(props.state)
        } else {
          setOpen(id);
            if (!reports.includes(props.reportType)) {
                props.state[props.name].push(props.reportType)
                props.setState(props.state)
            }
        }
    };

  return (
    <div>
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId={props.reportType}>
            {props.groupName}
          </AccordionHeader>
          <AccordionBody accordionId={props.reportType}>
            {props.children}
          </AccordionBody>
            {props.tooltipContent &&
            <UncontrolledTooltip placement="top" target={props.reportType}>
                {props.tooltipContent}
            </UncontrolledTooltip>}
        </AccordionItem>
      </Accordion>
      <br></br>
    </div>

  );
}

function DebitSettingsGroup(props) {
    const [open, setOpen] = useState('0');

    useEffect(() => {
        const reports = props.state[props.name] ? props.state[props.name] : defaultDebitTypes
        const reportsKeys = Object.keys(reports)
        if (reportsKeys.includes(props.reportType)) {
            setOpen(props.reportType);
        }
        else {
            setOpen("0")
        }
    }, [props.state]);

    const toggle = (id) => {
        if (open === id) {
            setOpen("0")
            delete props.state[props.name][props.reportType]
            props.setState({...props.state})
        } else {
            setOpen(id);
            props.state[props.name][props.reportType] = defaultDebitTypes[props.reportType]
            document.getElementById(props.reportType).value = defaultDebitTypes[props.reportType]
            props.setState({...props.state})
        }
        console.log('this state', props.state)
    };

    return (
        <div>
            <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId={props.reportType}>
                        {props.groupName}
                    </AccordionHeader>
                    <AccordionBody accordionId={props.reportType}>
                        {props.children}
                    </AccordionBody>
                    {props.tooltipContent &&
                        <UncontrolledTooltip placement="top" target={props.reportType}>
                            {props.tooltipContent}
                        </UncontrolledTooltip>}
                </AccordionItem>
            </Accordion>
            <br></br>
        </div>

    );
}

function UncontrolledSettingGroup(props) {
    return (
        <div>
          <UncontrolledAccordion defaultOpen="1">
              <AccordionItem>
                <AccordionHeader targetId="1">
                        {props.groupName}
                </AccordionHeader>
                <AccordionBody accordionId="1">
                    {props.children}
                </AccordionBody>
              </AccordionItem>
          </UncontrolledAccordion>
          <br></br>
        </div>
      );
}

function FhInventorySettingsGroup(props) {
    const [open, setOpen] = useState('0');

    useEffect(() => {
        const userSettings = getUserSettings()
        const inventoryCategroyObj = userSettings[props.qboAccount][props.name]
        if (Object.keys(inventoryCategroyObj).length > 0) {
            setOpen(props.name)
            props.state[inventoryCategoryStatus] = true
            props.setState({...props.state})
        } else {
            setOpen('0')
            props.state[inventoryCategoryStatus] = false
            props.setState({...props.state})
        }
    }, [props.qboAccount])

    const toggle = (id) => {
        if (open === id) {
            setOpen('0')
            props.state[inventoryCategoryStatus] = false
            props.setState({...props.state})
        } else {
            setOpen(id)
            props.state[inventoryCategoryStatus] = true
            props.setState({...props.state})
        }
    }

    return (
        <div>
            <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId={props.name}>
                        {props.groupName}
                    </AccordionHeader>
                    <AccordionBody accordionId={props.name}>
                        {props.children}
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
            <br></br>
        </div>
    );
}

export {SettingsGroup, DebitSettingsGroup, UncontrolledSettingGroup, FhInventorySettingsGroup};
