import {Alert, Button, ButtonGroup, Form} from "reactstrap";
import {UncontrolledSettingGroup} from "./settingsGroup";
import {FormField} from "./input";
import React, {useEffect, useState} from "react";
import {getHeader, setUserSession} from "../service/sessionStorage";
import axios from "axios";
import {handleError} from "../service/apiErrorHandler";
import MultiSelect from "./multiSelect";
import RunReportDetails from "./runReportDetails"
import {getUrl, reportTypes} from "../docs/data";
import {pollRunResults} from "../service/pollRunResults";


const ReportManagementGroup = (props) => {
    const header = getHeader()
    const [buttonType, setButtonType] = useState('historical')
    const [progress, setProgress] = useState(0);

    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [alertColor, setColor] = useState('')

    const [status, setStatus] = useState(false)

    useEffect(() => {
        setStatus(props.state.status);
    }, [props.state]);

    const enabledReports = props.state['report_types'] ? props.state['report_types'] : [];
    const options = reportTypes.filter((x) => enabledReports.includes(x.value));

    const makeRunRequest = async (event) => {
        event.preventDefault();
        const url = buttonType === 'historical' ? getUrl('historical') : getUrl('remove_reports')
        const resultName = buttonType === 'historical' ? 'historical_run_result' : 'remove_run_result'
        const messageHandler = {
            'setMessage': setMessage,
            'setVisible': setVisible,
            'setColor': setColor}

        console.log('Making a run request', props.state.start_date, props.state.run_report_types, url)
        if (!props.state.start_date) {
            setMessage('Start Date is required.');
            setVisible(true);
            setColor('warning');
            return
        }

        if (!props.state.run_report_types) {
            setMessage('Report type is required.');
            setVisible(true);
            setColor('warning');
            return
        }

        setProgress(0)
        const requestBody = {
            'qbo_acc_name': props.qboAccount,
            'start_date': props.state.start_date,
            'end_date': props.state.end_date,
            'report_types': props.state.run_report_types
        }

        console.log('body', requestBody)
        try {
            const response = await axios.post(url, requestBody, header);
            console.log(response)
            if (response.status === 200) {
                await setUserSession(response.data, response.headers);
                setMessage('Sync successfully started');
                setVisible(true);
                setColor('primary');

                pollRunResults(props.qboAccount, resultName, setProgress, messageHandler);
                }
            } catch (e) {
                handleError(e, setMessage, setVisible, setColor);
                console.log(e)
            }
    }

    const enableDisableRequest = (event) => {
        event.preventDefault();
        const requestBody = {'qbo_acc_name': props.qboAccount}

        const url = getUrl('enable_disable')
        axios.post(url, requestBody, header)
            .then(response => {
                if (response.status === 200) {
                    setUserSession(response.data, response.headers);
                    setMessage(status ? 'Account disabled successfully' : 'Account enabled successfully');
                    setVisible(true);
                    setColor('primary')
                    setStatus(!status)
                }
            })
            .catch(error => handleError(error, setMessage, setVisible, setColor));
    }

    return (
        <UncontrolledSettingGroup groupName='Manual Report Sync Settings'>
            <Form className="form" onSubmit={makeRunRequest}>
                <FormField groupName='Reports` Start Date'
                           formName='Reports` Start Date'
                           placeholder=''
                           name='start_date'
                           type='date'
                           state={props.state}
                           setState={props.setState}
                           tooltipContent='This will be the first report date'/>
                <FormField groupName='Reports` End Date'
                           formName='Reports` End Date'
                           placeholder=''
                           name='end_date'
                           type='date'
                           state={props.state}
                           setState={props.setState}
                           tooltipContent='Until & not including this date'/>
                <MultiSelect groupName='Module Types'
                             formName='Module Types'
                             name='run_report_types'
                             state={props.state}
                             setState={props.setState}
                             tooltipContent='Modules that will be used during reports upload'
                             options={options}
                />
                <div>
                    {props.state['run_lock'] ? <RunReportDetails state={props.state}></RunReportDetails> : null}
                    <label id='syncProgressBar'>
                        Sync Progress: <progress value={progress} max="100">{progress} %</progress>
                    </label>
                </div>

                <br></br>
                {message && <Alert color={alertColor} isOpen={visible} toggle={() => setVisible(false)} className="message">{message}</Alert>}
                <br></br>

                <ButtonGroup>
                    <Button type="submit" name="remove_reports"  onClick={() => setButtonType('remove_reports')} color="secondary">
                        Remove Reports
                    </Button>
                    <Button type="submit" name="historical" onClick={() => setButtonType('historical')} color="success">
                        Add Reports
                    </Button>
                    <Button id="enableDisableBtn" type="button" onClick={enableDisableRequest} color="danger" >
                        {status ? 'App Turn Off' : 'App Turn On'}
                    </Button>
                </ButtonGroup>
            </Form>
        </UncontrolledSettingGroup>
    )
}

export {ReportManagementGroup};