import {FormField} from "../input";
import {SettingsGroup} from "../settingsGroup";
import React from "react";
import {Toggle} from "../toggle";

export const CanixIncomeSettings = ({state, setState}) => {
    return (
        <SettingsGroup groupName={<div id='INCOME'>Sales & Refund Receipts</div>}
                       reportType='INCOME'
                       name='report_types'
                       state={state}
                       setState={setState}
                       tooltipContent='Turn on to enable module. Creates receipts that holds sales summary for the day under register-named account. Summary: sales, discounts, rounding, non-revenue donations, cogs, tax (by type). Granularity: by register, day.'>
            <FormField groupName='Canix Income'
                       formName='Canix Item Target Name'
                       placeholder='36-figure Id'
                       state={state}
                       setState={setState}
                       name='canix_income_validation_name'
                       tooltipContent='Example: mojo'/>
            <FormField groupName='Canix Income'
                       formName='Facility Id to Class Map'
                       placeholder='Required'
                       name='canix_facility_type_map'
                       state={state}
                       setState={setState}
                       tooltipContent='Used to map specific products to the facility class. Ids can be checked at the API.
                                        Map it according to the template: {"1234":"Production"}.'/>
            <FormField groupName='Canix Income'
                       formName='Item Asset Account name'
                       placeholder='Default value: Inventory Asset'
                       name='inventory_acc_name'
                       state={state}
                       setState={setState}/>
            <FormField groupName='Canix Income'
                       formName='Item Inventory account type'
                       placeholder='Default value: Other Current Asset'
                       state={state}
                       setState={setState}
                       name='inventory_acc_type'/>
        </SettingsGroup>
    )
}