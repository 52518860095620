import {FormField} from "../input";
import {UncontrolledSettingGroup} from "../settingsGroup";
import React from "react";
import {BasicSelector} from "../select";
import {accountingBasis} from "../../docs/data";
import {SingleSelect} from "../singleSelect";

export const CanixGlobalSettings = ({state, setState, qboAccNames}) => {
    return (
        <UncontrolledSettingGroup groupName='Global Settings'>
            <SingleSelect groupName='Global Settings'
                          formName='Registers Parent Bank Account Name'
                          name='parent_acc_name'
                          state={state}
                          setState={setState}
                          tooltipContent='This account will be parent to all register-dedicated accounts. In case of Lite mode - this will be the account name to hold all sales receipts and debit journals'
                          options={qboAccNames}/>
            <FormField groupName='Canix API Key'
                       formName='Canix API Key'
                       placeholder='64-figure key'
                       state={state}
                       setState={setState}
                       name='canix_token'
                       type='password'
                       tooltipContent='Example: bd689b051e888f32af7fcd22a16e3cd1656c2ff53e14438ggg5b072b8abb2fa1'/>
            <BasicSelector formName='Accounting Basis'
                           name='accounting_basis'
                           componentName='AccountingBasis'
                           state={state}
                           setState={setState}
                           items={['Cash']}/>
        </UncontrolledSettingGroup>
    )
}