import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Alert,
} from 'reactstrap';
import {FormField} from "./input";
import {getHeader, setUserSession} from "../service/sessionStorage";
import axios from "axios";
import {handleError} from "../service/apiErrorHandler";
import {getUrl} from "../docs/data";


function BugSubmissionModal(props) {
    const [modal, setModal] = useState(false);
    const state = {'bug_integration_type':'',
                    'bug_date': '',
                    'bug_expected_result': '',
                    'bug_actual_result': '',
                    'bug_notes': ''}
    const toggle = () => setModal(!modal);

    const [message, setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const [alertColor, setColor] = useState('')
    const onDismiss = () => setVisible(false);

    const sendBugReport = (event) => {
        event.preventDefault();
        if (!props.qboAccount) {
            setMessage('Please select QBO account your report applies to');
            setVisible(true);
            setColor('warning')
            return
        }

        if (Object.values(state).every(value => !value)) {
            setMessage('Please, provide details to proceed');
            setVisible(true);
            setColor('warning')
            return
        }

        const url = getUrl('bug_report')
        const header = getHeader()
        const requestBody = {};
        requestBody['bug_details'] = state
        requestBody['qbo_acc_name'] = props.qboAccount


        console.log('Sending bug report with body ', requestBody)
        axios.post(url, requestBody, header)
            .then(response => {
                if (response.status === 200) {
                    setUserSession(response.data, response.headers);
                    setMessage('Bug report submitted successfully');
                    setVisible(true);
                    setColor('primary')
                }
            })
            .catch(error => handleError(error, setMessage, setVisible, setColor));
    }

    return (
        <div id='bugSubmitModal'>
            <Form inline onSubmit={(e) => e.preventDefault()}>
                <Button color="danger" onClick={toggle}>
                    Submit a bug
                </Button>
            </Form>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Bug Report</ModalHeader>
                <ModalBody>
                    <FormField
                        groupName='Integration Type'
                        formName='Integration Type'
                        placeholder='QBO name of the document (e.g. Sales Receipt, Bill)'
                        qboAccount={props.qboAccount}
                        state={state}
                        name='bug_integration_type'/>
                    <FormField
                        groupName='Date of Event'
                        formName='Date of Event'
                        placeholder='Date of the first bug encounter'
                        qboAccount={props.qboAccount}
                        state={state}
                        name='bug_date'/>
                    <FormField
                        groupName='Expected Result'
                        formName='Expected Result'
                        placeholder='Provide expected data (e.g. true figures from ductie ui)'
                        qboAccount={props.qboAccount}
                        state={state}
                        name='bug_expected_result'/>
                    <FormField
                        groupName='Actual Result'
                        formName='Actual Result'
                        placeholder="Provide data you've got for that date in qbo"
                        qboAccount={props.qboAccount}
                        state={state}
                        name='bug_actual_result'/>
                    <FormField
                        groupName='Additional Notes'
                        formName='Additional Notes'
                        placeholder="Free form description. Gladly hear your guesses and tips (-:"
                        qboAccount={props.qboAccount}
                        state={state}
                        name='bug_notes'/>
                </ModalBody>
                <ModalFooter>
                    {message && <Alert color={alertColor} isOpen={visible} toggle={onDismiss} className="message">{message}</Alert>}
                    <br></br>
                    <Button color="primary" onClick={sendBugReport}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default BugSubmissionModal;