import {FormField} from "../input";
import {SettingsGroup} from "../settingsGroup";
import React from "react";
import {FlowhubDebitSettings} from "./FlowhubDebitSettings";
import {FlowhubInventorySettings} from "./FlowhubInventorySettings";
import {SingleSelect} from "../singleSelect";

export const InventorySettings = ({state, setState, qboAccount, qboAccNames}) => {
    if (state.integration_name === 'CANIX') return
    if (state.integration_name === 'FLOWHUB') return <FlowhubInventorySettings state={state} setState={setState} qboAccount={qboAccount} qboAccNames={qboAccNames}/>

    return (
        <SettingsGroup groupName={<div id='INVENTORY'>Vendor Bills</div>}
                       reportType='INVENTORY'
                       name='report_types'
                       state={state}
                       setState={setState}
                       tooltipContent='Turn on to enable QBO Bills. Module creates bills in the "expanses" section of the QBO. Granularity: total cost of goods in received inventory entry'>
            <FormField groupName='Vendor Tracking Class'
                       formName='Tracking class for vendor bills'
                       placeholder='Default value: Disabled'
                       name='inventory_tracking_class'
                       state={state}
                       setState={setState}/>
            <SingleSelect groupName='Vendor Bills'
                          formName='Inventory account name'
                          name='inventory_acc_name'
                          state={state}
                          setState={setState}
                          options={qboAccNames}/>
            <FormField groupName='Vendor Names Mapping'
                       formName='Vendor names mapping'
                       placeholder='Possible value: {"Vendor Original Name": "Vendor Custom Name"}'
                       state={state}
                       setState={setState}
                       name='inventory_vendor_naming_map'
                       tooltipContent='Relation map. Can be used to rename vendors in QBO or assign several vendor names from POS to a single QBO vendor.
                               Template: {"POS name 1":"QBO name", "POS name 2":" QBO name"}'/>
            <FormField groupName='Exclude Vendors'
                       formName='Exclude Vendors'
                       placeholder='Possible value: VendorName,AnotherVendorName'
                       state={state}
                       setState={setState}
                       name='inventory_exclude_vendors'
                       tooltipContent='List of vendor names to exclude during QBO bills creation. Vendor name should be put as it is listed in: Dutchie > Reports > Inventory > Current Inventory.
                               Template: VendorName,AnotherVendorName'/>
            <FormField groupName='Vendor Bills'
                       formName='Inventory account type'
                       placeholder='Default value: Other Current Asset'
                       state={state}
                       setState={setState}
                       name='inventory_acc_type'/>
            <FormField groupName='Vendor Bills'
                       formName='Payment Term'
                       placeholder='Default value: Net 30'
                       name='inventory_sales_term_standard'
                       state={state}
                       setState={setState}/>
        </SettingsGroup>
    )
}